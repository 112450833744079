<template>
  <div class="view-history-battle">
    <CompHeader title="对战历史">
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="ten-battle">
      <div
        @click="roomItemOnClick(item)"
        v-for="item in finalBattleList"
        :key="item.id"
        class="battle-main-list-box-item">
        <BattleItem :item="item" />
      </div>
      <Pagination
        :total="pageTotal"
        :limit="pageSize"
        @pagination="pageChangeOnClick" />
    </div>
  </div>
</template>

<script>
import { My_Arena_List } from "@/network/api.js";
import BattleItem from "./components/BattleItem.vue";
export default {
  components: { BattleItem },
  name: "historyBattle",
  data() {
    return {
      list: [],
      // 分页数据
      pageTotal: 1,
      pageSize: 1,
      currentPage: 1,
    };
  },
  created() {
    this.init();
  },
  computed: {
    finalBattleList() {
      return (this.list || []).map((wrapItem) => {
        let newSeatList = new Array(wrapItem.user_num).fill(null);
        newSeatList = newSeatList.map((wItem, index) => {
          let seatItem = wItem;
          wrapItem.game_arena_player.forEach((vItem) => {
            if (vItem.seat === index) {
              seatItem = vItem;
            }
          });
          return seatItem;
        });
        return {
          ...wrapItem,
          newSeatList,
        };
      });
    },
  },
  methods: {
    init() {
      console.log("init");
      this.getListFun();
    },
    pageChangeOnClick(item) {
      this.currentPage = item.page;
      this.getListFun();
    },
    roomItemOnClick(item) {
      this.$router.push({
        path: "/battleRoom",
        query: {
          id: item.id,
        },
      });
    },
    async getListFun() {
      try {
        const params = {
          page: this.currentPage,
          //   status: 0,
        };
        const res = await My_Arena_List(params);
        console.log("历史对战", res);
        if (res?.data?.code === 200) {
          this.list = res?.data?.data?.data;
          this.pageTotal = res?.data?.data?.total;
          this.pageSize = res?.data?.data?.per_page;
          console.log(33333, this.pageTotal, this.pageSize);
        } else {
          this.$message.error(res?.data?.message);
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.view-history-battle {
  min-height: 100vh;
  padding: 0.5rem 0rem 0.9rem;
  position: relative;
  //   &::after {
  //     content: "";
  //     width: 100vw;
  //     height: 100vh;
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     z-index: -99;
  //     @include bgImg("battle-bg");
  //   }
  .ten-battle {
    padding: 0.12rem 0.2rem 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    .item {
      width: 1.6rem;
      height: 2.05rem;
      margin-bottom: 0.15rem;
      background: #ccc;
    }
  }
}
</style>
